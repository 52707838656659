import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_RowSelectionState,
  MRT_VisibilityState,
  MRT_ColumnOrderState,
  MRT_GroupingState,
  MRT_SortingState,
  MRT_ExpandAllButton,
  MRT_ExpandButton,
  MRT_ColumnFiltersState,
  MRT_ColumnFilterFnsState,
  MRT_ToolbarAlertBanner,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_TablePagination,
  MRT_FilterFn,
  MRT_Row
} from 'material-react-table';

//Material UI Imports
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  lighten,
} from '@mui/material';

//Icons Imports
import {
  AccountCircle,
  Send,
  Delete,
  ContentCopy,
  Save,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
  RefreshOutlined,
} from '@mui/icons-material';
import { useSearchParams } from "react-router-dom";

import { ATRecord, Jobs, getField, Companies, update, getFieldId, Branches, Contacts } from '@rogoag/airtable';
import { getBoundaryForJob, getUsersData, getJobs, getPointsForJob, getBranches, updateUserPortalSettings, updateCompanyPortalViews } from '../api/airtable_ops';
import { UserSettings, ViewCollection, ViewDefinition, ViewGroup } from '../types';
import { checkboxColumn, checkboxMultiselectColumn, dateColumn, fileColumn, numericColumn, textColumn } from './MRT_AirTableHelpers';
import { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import { UserContext } from '../hooks/UserContext';
import ViewSaveDialog from './ViewSaveDialog';
import AirtableRecordSelectionAutocomplete from './AirtableRecordSelectionAutocomplete';
import { toast } from 'react-toastify';
import { sleep } from '../utils';
//import useUrlState from '@ahooksjs/use-url-state';
import { MarkJobReadyPopover } from './MarkJobReadyPopover';
import { LoadingComponent } from './LoadingComponent';
import { Props as ViewSaveDialogProps } from './ViewSaveDialog';

import * as Sentry from '@sentry/react';


const DEFAULT_FILTER_FNS: MRT_ColumnFilterFnsState = {
  'sample date': 'greaterThanOrEqualTo',
  'field ready date': 'greaterThanOrEqualTo',
  'boundary acres': 'between',
  'job status': 'fuzzy',
  field: 'fuzzy',
  farm: 'fuzzy',
  'creation date': 'greaterThanOrEqualTo',
  'drop/ship date': 'greaterThanOrEqualTo',
  'test package': 'fuzzy',
  edits: 'fuzzy',
  'files last edited?': 'greaterThanOrEqualTo',
  submitter: 'fuzzy',
  branch: 'fuzzy',
  'map issue': 'equals',
  'lab sent results': 'greaterThanOrEqualTo',
  'boundary approved?': 'equals',
  'boundary change?': 'equals',
  'points change?': 'equals',
  'season': 'fuzzy',
} as const;

const DEFAULT_FILTERS: MRT_ColumnFiltersState = [];
const DEFAULT_GROUPING: MRT_GroupingState = ['grower'];
const DEFAULT_COLUMN_SORT: MRT_SortingState = [];
const DEFAULT_VIS_STATE: MRT_VisibilityState = {};
const DEFAULT_COLUMN_ORDER: MRT_ColumnOrderState = [
  "mrt-row-select",
  "mrt-row-expand",
  "grower",
  "field",
  "farm",
  "job status",
  "boundary acres",
  "field ready date",
  "sample date",
  "creation date",
  "drop/ship date",
  "test package",
  "edits",
  "files last edited?",
  "submitter",
  "approved billing?",
  "boundary",
  "points",
  "sample zones",
  "executed points",
  "executed boundary",
  "executed points + boundary",
  "lab results sent date",
  "lab results",
  "branch",
  "job flags",
  "lab sent results",
]

const PARAM_SEPARATOR = '|';

const dashboardStateToURLParams = (state: DashboardURLState): URLSearchParams => {
  const params = new URLSearchParams()
  const {
    columnVisibility,
    sorting,
    grouping,
    columnOrder,
    columnFilterFunctions,
    columnFilterValues
  } = state;
  const urlSorting = sorting.map(sort => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`).join(PARAM_SEPARATOR);
  const urlGrouping = grouping.join(PARAM_SEPARATOR);
  const urlColumnOrder = columnOrder.join(PARAM_SEPARATOR);
  const urlColumnVisibility = Object.keys(columnVisibility).map(key => `${key}=${columnVisibility[key]}`).join(PARAM_SEPARATOR);
  const urlFunctions = Object.keys(columnFilterFunctions).map(key => `${key}=${columnFilterFunctions[key]}`).join(PARAM_SEPARATOR);
  const urlFilterValues = columnFilterValues.map(filter => `${filter.id}:${filter.value}`).join(PARAM_SEPARATOR);

  params.set('sort', urlSorting);
  params.set('group', urlGrouping);
  params.set('vis', urlColumnVisibility);
  params.set('order', urlColumnOrder);
  params.set('fns', urlFunctions);
  params.set('filter', urlFilterValues);

  params.sort();
  return params;
}

const DEFAULT_VIEW_NAME = 'Group by Season, Branch, Grower';

const DEFAULT_VIEWS: ViewCollection = [
  {
    name: DEFAULT_VIEW_NAME,
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: DEFAULT_VIS_STATE,
      grouping: ['season', 'branch', 'grower'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Group by Status',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: DEFAULT_VIS_STATE,
      grouping: ['job status'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Unready Jobs',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: 'job status', value: 'Unready' },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "grower": true,
        "field": true,
        "farm": true,
        "job status": true,
        "boundary acres": false,
        "field ready date": false,
        "sample date": false,
        "creation date": false,
        "drop/ship date": false,
        "test package": false,
        "edits": false,
        "files last edited?": false,
        "submitter": false,
        "approved billing?": false,
        "boundary": false,
        "points": false,
        "sample zones": false,
        "executed points": false,
        "executed boundary": false,
        "executed points + boundary": false,
        "lab results sent date": false,
        "lab results": false,
        "branch": false,
        "job flags": false,
        "lab sent results": false,
        'points changed?': true,
        'boundary changed?': true,
        'boundary approved?': true,
      },
      grouping: ['grower'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Boundary Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: 'boundary change?', value: true },
        { id: 'boundary approved?', value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "grower": true,
        "field": true,
        "farm": true,
        "job status": true,
        "boundary acres": false,
        "field ready date": false,
        "sample date": false,
        "creation date": false,
        "drop/ship date": false,
        "test package": false,
        "edits": false,
        "files last edited?": false,
        "submitter": false,
        "approved billing?": false,
        "boundary": false,
        "points": false,
        "sample zones": false,
        "executed points": false,
        "executed boundary": true,
        "executed points + boundary": true,
        "lab results sent date": false,
        "lab results": false,
        "branch": false,
        "job flags": false,
        "lab sent results": false,
        'points changed?': true,
        'boundary changed?': true,
        'boundary approved?': true,
      },
      grouping: ['grower'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Points Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: 'points change?', value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "grower": true,
        "field": true,
        "farm": true,
        "job status": true,
        "boundary acres": false,
        "field ready date": false,
        "sample date": false,
        "creation date": false,
        "drop/ship date": false,
        "test package": false,
        "edits": false,
        "files last edited?": false,
        "submitter": false,
        "approved billing?": false,
        "boundary": false,
        "points": false,
        "sample zones": false,
        "executed points": true,
        "executed boundary": false,
        "executed points + boundary": true,
        "lab results sent date": false,
        "lab results": false,
        "branch": false,
        "job flags": false,
        "lab sent results": false,
        'points changed?': true,
        'boundary changed?': true,
        'boundary approved?': true,
      },
      grouping: ['grower'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  }
];

interface DashboardURLState {
  sorting: MRT_SortingState;
  grouping: MRT_GroupingState;
  columnOrder: MRT_ColumnOrderState;
  columnVisibility: MRT_VisibilityState;
  columnFilterFunctions: MRT_ColumnFilterFnsState;
  columnFilterValues: MRT_ColumnFiltersState;
}


const urlParamsToDashboardState = (urlParams: URLSearchParams): DashboardURLState => {
  const rawSortParam = urlParams.get('sort');
  const rawGroupParam = urlParams.get('group');
  const rawOrderParam = urlParams.get('order');
  const rawVisParam = urlParams.get('vis');
  const rawFnsParam = urlParams.get('fns');
  const rawFilterParam = urlParams.get('filter');
  const columnFilterValues = rawFilterParam ? rawFilterParam.split(PARAM_SEPARATOR).map(filter => ({ id: filter.split(':')[0], value: filter.split(':')[1] })) : [];
  console.log('rawFilterParam', rawFilterParam, 'columnFilterValues', columnFilterValues);
  const viewState: DashboardURLState = {
    sorting: rawSortParam ? rawSortParam.split(PARAM_SEPARATOR).map(sort => ({ id: sort.split(':')[0], desc: sort.split(':')[1] === 'desc' })) : [],
    grouping: rawGroupParam ? rawGroupParam.split(PARAM_SEPARATOR) : [],
    columnOrder: rawOrderParam ? rawOrderParam.split(PARAM_SEPARATOR) : [],
    columnVisibility: rawVisParam ? rawVisParam.split(PARAM_SEPARATOR).reduce((acc: MRT_VisibilityState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value === 'true';
      return acc;
    }, {}) : {},
    columnFilterFunctions: rawFnsParam ? rawFnsParam.split(PARAM_SEPARATOR).reduce((acc: MRT_ColumnFilterFnsState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value;
      return acc;
    }, {}) : {},
    columnFilterValues
  };
  return viewState;
}

interface Props {
  setDrawerOpenState: (open: boolean) => void;
  setDisplayBoundaries: (boundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>) => void;
  setPoints: (points: Record<string, FeatureCollection<Point>>) => void;
  setLoading: (loading: boolean) => void;
  drawerOpen: boolean;
}

const isBetaPortal = window.location.hostname.toLowerCase().includes('portalbeta');

export const JobsDashboardTable = (props: Props) => {
  const userContext = useContext(UserContext);

  const [inputDialogState, setInputDialogState] = useState<ViewSaveDialogProps>({
    open: false,
    title: '',
    content: '',
    submitText: '',
    submit: () => { },
    viewName: '',
  });
  // use react router search params to filter the data
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchStateParams = () => {
    const urlParams = dashboardStateToURLParams({
      sorting,
      grouping,
      columnOrder,
      columnVisibility,
      columnFilterFunctions,
      columnFilterValues
    });
    urlParams.sort()
    setSearchParams(urlParams);
  }

  // dashboard state
  const dashboardState = urlParamsToDashboardState(searchParams);
  const [sorting, setSorting] = useState<MRT_SortingState>(dashboardState.sorting ?? DEFAULT_COLUMN_SORT);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(dashboardState.columnVisibility ?? DEFAULT_VIS_STATE);
  const [grouping, setGrouping] = useState<MRT_GroupingState>(dashboardState.grouping ?? DEFAULT_GROUPING);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(dashboardState.columnOrder ?? DEFAULT_COLUMN_ORDER);
  const [columnFilterValues, setColumnFilterValues] = useState<MRT_ColumnFiltersState>(DEFAULT_FILTERS);
  const [columnFilterFunctions, setColumnFilterFunctions] = useState<MRT_ColumnFilterFnsState>(DEFAULT_FILTER_FNS);

  const [retrievingJobs, setRetrievingJobs] = useState(false);
  const [updatingBoundaries, setUpdatingBoundaries] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<ATRecord<Companies>[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<ATRecord<Companies> | null>(null);
  const [views, setViews] = useState<ViewCollection>(DEFAULT_VIEWS);
  const [selectedViewDirty, setSelectedViewDirty] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<ViewDefinition | null>(null);
  const [branches, setBranches] = useState<ATRecord<Branches>[]>([]);

  const [jobs, setJobs] = useState<ATRecord<Jobs>[]>([]);
  const [userRecord, setUserRecord] = useState<ATRecord<Contacts>>();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectionDisplayedOnMap, setSelectionDisplayedOnMap] = useState<MRT_RowSelectionState>({});

  const [markJobsReadyVisible, setMarkJobsReadyVisible] = useState(false);

  const selectedJobs = Object.keys(rowSelection).map((key) => {
    const index = parseInt(key);
    if (!isNaN(index) && rowSelection[key]) {
      return jobs[index];
    }
  }) as ATRecord<Jobs>[];

  const unreadySelectedJobs = selectedJobs.filter(job => !getField(job, "Field Ready Date"));

  useEffect(() => {
    // TODO Might not be necessary, might be ROGO user only setting
    const paramsCopy = new URLSearchParams(searchParams.toString());
    paramsCopy.sort();

    const selectedParams = new URLSearchParams(selectedView?.url ?? '');
    selectedParams.sort();

    setSelectedViewDirty(selectedView === null || selectedParams.toString() !== paramsCopy.toString());

    updateSearchStateParams();
  }, [selectedView, grouping, sorting, columnOrder, columnVisibility, columnFilterFunctions, columnFilterValues]);

  async function onCompanyChanged(value: ATRecord<Companies> | null) {
    setRetrievingJobs(true);
    try {
      setSelectedCompany(value);
      if (companyOptions.length > 1) {
        // updateSearchParams('company', value?.id ?? '');
        updateSearchStateParams();
      }
      setRowSelection({});
      let jobs: ATRecord<Jobs>[] = [];

      // remove all company views from the views state
      // const newViews: ViewCollection = [...views.filter(view => view.group !== 'Company Views')];

      const userPortalSettingsString = getField(userRecord, "Portal Settings") as string;
      const userPortalSettings: UserSettings = userPortalSettingsString ? JSON.parse(userPortalSettingsString) : {};

      const newViews: ViewCollection = [
        ...userPortalSettings.views ?? []
      ];

      if (value) {
        jobs = await getJobs(value);

        const branches = await getBranches(value);
        setBranches(branches);

        // get company views from 'Portal Views' property on company record
        console.log(getFieldId("Companies", "Portal Views"));
        const portalViews = getField(value, "Portal Views") as string;
        if (portalViews) {
          const companyViews = JSON.parse(portalViews) as ViewDefinition[];
          for (const view of companyViews) {
            newViews.push({ ...view });
          }
        }
      }

      newViews.push(...DEFAULT_VIEWS);

      setViews(newViews);

      // if (!searchParams.toString()) {
      // TODO we should actually get the last visited view, or the customer default view here
      const defaultView = views.filter(view => view.name === DEFAULT_VIEW_NAME)[0];
      const urlParams = new URLSearchParams(defaultView.url);
      urlParams.sort();
      const dashboardState = urlParamsToDashboardState(urlParams);
      let grouping = dashboardState.grouping;
      if (branches.length <= 1) {
        grouping = grouping.filter(group => group !== 'branch');
      }
      setSorting(dashboardState.sorting);
      setGrouping(grouping);
      setColumnOrder(dashboardState.columnOrder);
      setColumnVisibility(dashboardState.columnVisibility);
      setSelectedView(defaultView);
      setSearchParams(urlParams);
      // }

      setJobs(jobs);
    } catch {
      // handle error
    } finally {
      setRetrievingJobs(false);
    }
  }

  const refreshJobs = async () => {
    try {
      if (!selectedCompany) {
        return;
      }
      setRetrievingJobs(true);
      const jobs = await getJobs(selectedCompany);
      setJobs(jobs);
    } catch (error) {
      toast.error('Failed to refresh jobs');
      Sentry.captureException(error, { mechanism: { handled: true } });
    } finally {
      setRetrievingJobs(false);
    }
  }


  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function getData() {
      try {
        // setTableIsLoading(true);
        if (userContext == undefined){
          Sentry.captureMessage("userContext is undefined: JobsDashboardTable.tsx", "info");
          return;
        }
        const [userRecord, companyRecords, deals, importOptions, usersCompany] = await getUsersData(userContext?.id);
        setUserRecord(userRecord);
        companyRecords.sort((companyA, companyB) => getField(companyA, "Name", "").localeCompare(getField(companyB, "Name", "")));
        setCompanyOptions(companyRecords);
        if (companyRecords.length === 1) {
          await onCompanyChanged(companyRecords[0]);
        } else if (companyRecords.length > 1) {
          const urlCompanyId = searchParams.get('company');
          if (urlCompanyId) {
            const company = companyRecords.find(company => company.id === urlCompanyId);
            if (company) {
              await onCompanyChanged(company);
            }
          }
        }
      } catch (error) {
        toast.error('Failed to load data');
        Sentry.captureException(error, { mechanism: { handled: true } });
      }
    };

    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!jobs.length) {
      getData();
    }
  }, [userContext]);

  // useEffect(() => {
  const updateBoundaries = async () => {
    try {
      // setUpdatingBoundaries(true);

      const selectedJobs = Object.keys(rowSelection).map((key) => {
        const index = parseInt(key);
        if (!isNaN(index) && rowSelection[key]) {
          return jobs[index];
        }
      });

      // open the drawer if rows are selected
      props.setDrawerOpenState(Object.keys(rowSelection).length > 0);

      const displayedBoundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};
      const displayedPoints: Record<string, FeatureCollection<Point>> = {};
      await Promise.all(selectedJobs.map(async job => {
        if (!job) {
          return;
        }

        const [boundary, points] = await Promise.all([
          getBoundaryForJob(job),
          getPointsForJob(job)
        ]);
        if (boundary) {
          displayedBoundaries[job.id] = boundary;
        }

        if (points) {
          // @ts-ignore
          // TODO disabling for now. This will instead be turned on/selected on a per job basis. 
          displayedPoints[job.id] = points;
        }

      }));

      props.setDisplayBoundaries(displayedBoundaries);
      props.setPoints(displayedPoints);
      setSelectionDisplayedOnMap(rowSelection);
      return [Object.keys(displayedBoundaries).length, Object.keys(displayedPoints).length] as const;
    } catch (error) {
      console.error(error);
      return [0, 0] as const;
    } finally {
      // setUpdatingBoundaries(false);
    }
  }

  const columns = useMemo<MRT_ColumnDef<ATRecord<Jobs>>[]>(
    () =>
      [
        // The job name field is actually pretty ugly for a user facing value, and it's really just a concatenation of other fields 
        // so it is probably best for it not to be used
        // textColumn('Name'),
        textColumn('Grower', { size: 200 }),
        textColumn('Field', { accessorKeyOrFn: 'Field Name Clean' }),
        textColumn('Farm', { accessorKeyOrFn: 'Farm Name Clean' }),
        textColumn('Job Status', { accessorKeyOrFn: 'Job Status - Simple' }),
        numericColumn('Boundary Acres', { aggregationFn: 'sum' }),

        // this column doesn't seem to have good data for this customer
        // numericColumn('Acres Eq.'),

        textColumn("Branch", {
          accessorKeyOrFn: (record => {
            const branchIds = getField(record, "Branch / Location #form");
            if (!branchIds) {
              return "";
            }

            if (!branchIds.length) {
              return "";
            }

            const branchId = branchIds[0];

            const branch = branches.find(branch => branch.id === branchId);

            if (!branch) {
              return "";
            }

            return getField(branch, "Branch Name", branchId);
          })
        }),
        // ((record) => {
        //   const branchIds = getField(record, "Branch / Location #form");
        //   if (!branchIds) {
        //     return "";

        //   if (!branchIds.length) {
        //     return "";
        //   }

        //   const branchId = branchIds[0];
        //   console.log(`branchId`, branchId);
        //   const branch = branches.find(branch => branch.id === branchId);
        //   console.log(`branch`, branch);
        //   return getField(branch, "Branch Name", branchId) as unkown as string;
        //  })}),
        dateColumn('Field Ready Date'),
        dateColumn('Sample Date'),
        dateColumn('Creation Date', { accessorKeyOrFn: 'Creation Date with Time' }),
        dateColumn('Drop/Ship Date', { defaultText: 'Not shipped' }),
        textColumn("Test Package", { accessorKeyOrFn: "Test Pckg - Display" }),
        // textColumn("Edits", { accessorKeyOrFn: "Edits by Customer" }),
        // dateColumn("Files Last Edited?", { accessorKeyOrFn: "Edit Files Complete Date (latest)", defaultText: 'No edits' }),
        checkboxColumn('Boundary Change?', {
          accessorKeyOrFn: (record) => {
            const hasBoundaryChange = !!(getField(record, 'Bdy Change?') || false);
            return hasBoundaryChange;
          }
        }),
        checkboxColumn('Boundary Approved?', {
          accessorKeyOrFn: (record) => {
            const hasPointsUpdates = !!(getField(record, 'Bdy Approved for Send?') || false);
            return hasPointsUpdates;
          }
        }),
        checkboxColumn('Points Change?', {
          accessorKeyOrFn: (record) => {
            const hasPointsUpdates = !!(getField(record, 'Pts Created/Edited?') || false);
            return hasPointsUpdates;
          }
        }),
        // Lab Results
        textColumn("Submitter"),

        textColumn("Season"),

        // TODO branch column
        // TODO Filter

        checkboxColumn('Approved Billing?', { accessorKeyOrFn: '$ Approved Billing?' }),

        checkboxMultiselectColumn('Job Flags', 'Map Issue'),

        // BILLING FIELDS
        // textColumn('$ Approved Billing?'),
        // numericColumn('Base Price', { accessorKeyOrFn: 'Base Price Subtotal - 1st Entity #$ Prim', money: true, aggregationFn: undefined }),
        // numericColumn('Total Addon Price', { accessorKeyOrFn: 'Total Addons Subtotal #$ Calc', money: true, aggregationFn: undefined }),
        // numericColumn('Total Price', { accessorKeyOrFn: 'Total Price Subtotal #$ Calc', money: true, aggregationFn: undefined }),
        // numericColumn('$/Ac Cost', { accessorKeyOrFn: '$/Ac Cost - 1st Entity', money: true, aggregationFn: undefined }),
        // numericColumn('Adjustments', { accessorKeyOrFn: 'Addons Text (Adjustments)', money: true, aggregationFn: undefined }),

        // ops template view

        // $ Approved Billing?
        // Base Price Subtotal - 1st Entity #$ Prim
        // Total Addons Subtotal #$ Calc
        // Total Price Subtotal #$ Calc
        // $/Ac Cost - 1st Entity
        // Addons Text (Adjustments)

        // COMPANY OVERVIEW
        // Acres Short of Commit for Company
        // Credit Left $ for Company

        fileColumn('Boundary', { accessorKeyOrFn: 'Bnd Shp' }),
        fileColumn('Points', { accessorKeyOrFn: 'Pts Shp' }),
        fileColumn('Sample Zones', { accessorKeyOrFn: 'Sample Zones Shp' }),
        fileColumn('Executed Points', { accessorKeyOrFn: 'Exe Pts Shp' }),
        fileColumn('Executed Boundary', { accessorKeyOrFn: 'Exe Bnd Shp' }),
        fileColumn('Executed Points + Boundary', { accessorKeyOrFn: 'Exe Pts + Bnd Shps' }),

        dateColumn("Lab Sent Results", { accessorKeyOrFn: 'Lab Results Sent Date', defaultText: 'Not Sent' }),
        fileColumn('Lab Results'),
        fileColumn('Sampling CSV', { accessorKeyOrFn: 'CSV checkin - lab' }),
      ],
    [branches],
  );

  const hasSelections = Object.keys(rowSelection).length > 0;
  const hasGrouping = grouping.length > 0;
  const tableHeight = hasSelections && hasGrouping ? `calc(100vh - 240px)` : `calc(100vh - 190px)`;

  const table = useMaterialReactTable({
    columns,
    data: jobs, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnFilters: true,
    columnFilterDisplayMode: 'subheader',
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableHiding: true,
    enableFacetedValues: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTableFooter: true,
    enableMultiSort: true,
    enableGlobalFilterModes: true,
    enableGlobalFilterRankedResults: true,
    // enableRowActions: true,
    // muiTableContainerProps: { sx: { height: `calc(100vh - 240px)` }, },
    muiTableContainerProps: { sx: { height: tableHeight }, },

    muiTablePaperProps: { sx: { height: '100vh - 30px)' } },
    enableRowSelection: true,
    maxMultiSortColCount: 3,
    initialState: {
      expanded: true,
      showGlobalFilter: true,
      density: 'compact',
      pagination: {
        pageSize: 20,
        pageIndex: 0
      },
    },
    // TODO custom filter function logic
    // filterFns: {
    //   // TODO need to better defined 'row'
    //   myCustomFilterFn: (row, columnId: string, filterValue: string) => {
    //     // custom filter logic here
    //     console.log(columnId);
    //     if (columnId === 'grower' || columnId === 'field') {
    //       const rowValue = row.getValue(columnId);
    //       console.log(rowValue, filterValue);
    //       return !!rowValue?.toString().toLowerCase().includes(filterValue.toLowerCase());
    //     }

    //     return false;
    //   } 
    // },
    // globalFilterFn: 'myCustomFilterFn',
    selectAllMode: 'all',
    state: {
      // URL based state
      columnVisibility,
      // grouping: grouping.filter(group => group !== 'branch' || branches.length > 1),
      grouping,
      sorting,
      columnOrder,
      columnFilters: columnFilterValues,
      columnFilterFns: columnFilterFunctions,

      // Other state
      rowSelection,

      isLoading: retrievingJobs || updatingBoundaries,
    },
    enableSubRowSelection: true,
    muiCircularProgressProps: {
      Component: <LoadingComponent />,
    },
    onGroupingChange: setGrouping,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnFilterFnsChange: setColumnFilterFunctions,
    onColumnFiltersChange: setColumnFilterValues,
    onSortingChange: setSorting,
    groupedColumnMode: 'remove',
    displayColumnDefOptions: {
      'mrt-row-select': {
        visibleInShowHideMenu: true,
        // enableHiding: true,
      },
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        // @ts-ignore
        GroupedCell: ({ row, column }) => {
          const value = row.getValue(grouping[grouping.length - 1]);
          // if date, return formatted date
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return value;
        },
        Cell: ({ row, column }) => {
          const groupingId = grouping[Math.min(row.depth, grouping.length - 1)];
          const value = row.getValue(groupingId);

          let label: string | unknown = value
          // if date, return formatted date
          if (value instanceof Date) {
            label = value.toLocaleDateString();
          }
          return (
            <Stack direction="row" alignItems="center">
              <MRT_ExpandButton row={row} table={table} />
              {/* <Box>{titleCase(groupingId)}: {label?.toString() || "n/a"}</Box> */}
              <Box>{label?.toString() || "n/a"}</Box>
            </Stack>
          );
        },
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 200,
        visibleInShowHideMenu: true,
      },
    },
    muiToolbarAlertBannerProps: {
      // children: [],
      // title: "Title",
    },
    // muiToolbarAlertBannerChipProps: {
    // },
    paginationDisplayMode: 'default',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 15, 20, 30, 50],
      shape: 'rounded',
      variant: 'outlined',
      showRowsPerPage: !props.drawerOpen,
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       justifyContent: 'space-around',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <Box sx={{ textAlign: 'center' }}>
    //       <Typography variant="h4">Job Details:</Typography>
    //       <Typography variant="h1">
    //         {/* &quot;{row.original.signatureCatchPhrase}&quot; */}
    //       </Typography>
    //     </Box>
    //   </Box>
    // ),
    renderRowActionMenuItems: ({ closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic..
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],
    // renderBottomToolbar: ({ table }) => {
    //   return <Box display='flex' >
    //     <MRT_ToolbarAlertBanner
    //       // stackAlertBanner={stackAlertBanner}
    //       table={table}
    //     />
    //     <MRT_TablePagination table={table} />
    //   </Box>
    // },
    renderTopToolbar: ({ table }) => {
      const noJobs = jobs.length === 0;
      const noViewSelected = !selectedView;
      const viewIsReadonly = !!selectedView && selectedView.group === ViewGroup.Default;

      // we will actually always allow the save button when we have a default view selected
      // that way we will allow the user to "copy" the view by saving it under their own name
      const canSaveViewState = !viewIsReadonly && !selectedViewDirty;
      const cantSaveViewState = !canSaveViewState;
      const saveViewDisabled = noJobs && cantSaveViewState;
      const deleteViewDisabled = viewIsReadonly || noJobs || noViewSelected;
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            // gap: '0.5rem',
            pl: '8px',
            // justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>

          {/* These are the components that we will pin to the right */}
          <Box sx={{ marginLeft: 'auto', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {/* only show view controls if NOT expanded */}
              {!props.drawerOpen && (
                <>
                  {companyOptions.length > 1 &&
                    <AirtableRecordSelectionAutocomplete
                      key="Name"
                      loading={false}
                      options={companyOptions}
                      selectedOption={selectedCompany}
                      onChange={onCompanyChanged}
                      label="Choose your company"
                      style={{ width: '20vw', marginTop: 11 }}
                      tooltip="Select a company to view their jobs and views. This is only for ROGO employees"
                      placement='right'
                      size="small"
                    />
                  }
                  <Autocomplete
                    style={{ marginTop: 11, width: '15vw' }}
                    options={views}
                    value={selectedView}
                    disabled={jobs.length === 0}
                    groupBy={(option) => option.group === 'Default' ? 'System Views' : option.group.toString()}
                    getOptionKey={(option) => option.group + option.name}
                    getOptionLabel={(option) => option.name}
                    onChange={async (event, value) => {
                      event.stopPropagation();
                      if (!value) {
                        setSelectedView(value);
                        return;
                      }

                      try {
                        const urlParams = new URLSearchParams(value.url);
                        urlParams.sort();
                        const dashboardState = urlParamsToDashboardState(urlParams);
                        setSorting(dashboardState.sorting);
                        let grouping = dashboardState.grouping;
                        // This is special logic to filter out the branch grouping if there is only one branch
                        if (branches.length <= 1) {
                          grouping = grouping.filter(group => group !== 'branch');
                        }
                        setGrouping(grouping);
                        setColumnOrder(dashboardState.columnOrder);
                        setColumnVisibility(dashboardState.columnVisibility);
                        setColumnFilterValues(dashboardState.columnFilterValues);
                        setColumnFilterFunctions(dashboardState.columnFilterFunctions);

                        setSearchParams(urlParams);
                        setSelectedView(value);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    renderInput={(params) =>
                      <TextField {...params} label="Select saved view" variant="outlined" size="small" />}
                  />
                  <IconButton
                    onClick={refreshJobs}
                    disabled={jobs.length === 0}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                  >
                    <RefreshOutlined fontSize='large' style={{ 'color': jobs.length === 0 ? 'grey' : 'blue' }} />
                  </IconButton>

                  <IconButton onClick={async () => {
                    let newName = selectedView?.name;
                    // TODO prompt for name
                    const result = await new Promise<[string, ViewGroup] | undefined>((resolve) => {
                      setInputDialogState({
                        open: true,
                        title: 'Save current view',
                        content: 'Please enter a name for this view',
                        submitText: 'Save',
                        submit: (result) => {
                          resolve(result);
                          setInputDialogState({ ...inputDialogState, open: false });
                        },
                        viewName: selectedView?.name ?? '',
                      });
                    });

                    if (!result) {
                      return;
                    }

                    const [name, type] = result;

                    const newViews: ViewCollection = [
                      ...views,
                    ];

                    const newView: ViewDefinition = {
                      name,
                      group: type,
                      url: searchParams.toString(),
                      lastUpdated: new Date().toISOString()
                    };
                    if (selectedView && name === selectedView.name && type === selectedView.group) {
                      newViews[newViews.indexOf(selectedView)] = newView;
                    } else {
                      // look for a view that has the same group type as our new value
                      for (const view of newViews) {
                        if (view.group === newView.group || view.group === ViewGroup.Default) {
                          // insert the view right before the first view with the same group
                          newViews.splice(newViews.indexOf(view), 0, newView);
                          break;
                          // because we always sort the Default group to the 
                          // end of the list, we can break here 
                        }
                      }
                    }
                    setViews(newViews);
                    setSelectedView(newView);

                    if (newView.group === ViewGroup.User) {
                      await updateUserPortalSettings(userContext?.id ?? "", { views: newViews.filter(view => view.group === ViewGroup.User) });
                    } else {
                      await updateCompanyPortalViews(selectedCompany!, newViews.filter(view => view.group === ViewGroup.Company));
                    }
                  }}
                    size={'medium'}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                    // variant='contained'
                    disabled={saveViewDisabled}>
                    <Save fontSize='large' style={{ 'color': saveViewDisabled ? 'grey' : 'green' }} />
                  </IconButton>
                  <IconButton
                    onClick={async () => {
                      try {
                        // if this fails, it will throw
                        await navigator.clipboard.writeText(window.location.href);

                        // otherwise if we got here, then it should have worked
                        toast.success('Link copied to clipboard');
                      } catch (error) {
                        toast.error('Failed to copy link to clipboard');
                      }
                    }}
                    size={'medium'}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                    disabled={noJobs}
                  >
                    <ContentCopy fontSize='large' style={{ 'color': noJobs || noViewSelected ? 'grey' : 'blue' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (selectedView) {
                        const newViews: ViewCollection = [...views];
                        const index = newViews.indexOf(selectedView);
                        newViews.splice(index, 1);
                        setViews(newViews);
                        setSelectedView(null);

                        if (selectedView.group === ViewGroup.User) {
                          updateUserPortalSettings(userContext?.id ?? "", { views: newViews.filter(view => view.group === ViewGroup.User) });
                        } else if (selectedView.group === ViewGroup.Company) {
                          updateCompanyPortalViews(selectedCompany!, newViews.filter(view => view.group === ViewGroup.Company));
                        }
                      }
                    }}
                    size={'medium'}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                    // variant='contained'
                    disabled={deleteViewDisabled}
                  >
                    <Delete fontSize='large' style={{ 'color': deleteViewDisabled ? 'grey' : 'red' }} />
                  </IconButton>
                </>
              )}

              {/* <Button onClick={() => {
                throw new Error('Not implemented');
              }} style={{
                height: '100%',
                marginTop: 12,
              }} color="primary" variant="contained">
                Export
              </Button> */}
              <Button onClick={() => {
                setMarkJobsReadyVisible(true);
              }}
                style={{
                  height: '100%',
                  marginTop: 12,
                }}
                color="primary"
                // disabled={unreadySelectedJobs.length === 0}
                variant="contained" >
                {unreadySelectedJobs.length === 0 ?
                  'Mark Jobs Ready' :
                  `Mark ${unreadySelectedJobs.length} Jobs Ready`
                }
              </Button>
              <Button
                onClick={async () => {
                  const loadingBoundariesToast = toast.loading('Updating boundaries...');
                  const [boundaryCount, pointCount] = await updateBoundaries();
                  toast.dismiss(loadingBoundariesToast);
                  toast.info(`Displaying ${boundaryCount} boundaries and ${pointCount} point groups`);
                }}
                style={{
                  height: '100%',
                  marginTop: 12,
                }}
                color="primary"
                variant="contained"
                disabled={
                  Object.keys(selectionDisplayedOnMap).length === Object.keys(rowSelection).length &&
                  Object.keys(rowSelection).every((key) => !!selectionDisplayedOnMap[key])
                }
              >
                {(selectionDisplayedOnMap !== rowSelection) && props.drawerOpen ? 'Update Map' : 'Show on Map'}
              </Button>
              <IconButton
                onClick={() => {
                  props.setDrawerOpenState(!props.drawerOpen);
                }}
                size={'medium'}
                style={{
                  marginTop: 6,
                  height: '100%',
                }}
                // variant='contained'
                disabled={noJobs} >
                {props.drawerOpen ?
                  <KeyboardDoubleArrowRight fontSize='large' style={{ 'color': noJobs ? 'grey' : undefined }} /> :
                  <KeyboardDoubleArrowLeft fontSize='large' style={{ 'color': noJobs ? 'grey' : undefined }} />
                }
              </IconButton>
              {/* <Button
                color="success"
                onClick={handleSkyImport}
                variant="contained"
              >
                Import from AgVance SKY Mapping
              </Button>
              <Button
                color="error"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDeactivate}
                variant="contained"
              >
                Deactivate
              </Button>
              <Button
                color="success"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleActivate}
                variant="contained"
              >
                Activate
              </Button> */}
              {/* <Button
                color="info"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleContact} 
                variant="contained"
              >
                Contact
              </Button> */}
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return <>
    <MarkJobReadyPopover
      open={markJobsReadyVisible}
      jobs={jobs.filter(job => !getField(job, "Field Ready Date"))}
      intialSelectedJobs={
        markJobsReadyVisible ?
          unreadySelectedJobs :
          []
      }
      setLoading={setRetrievingJobs}
      onSuccess={async () => {
        await refreshJobs();
      }}

      setOpen={(open) => setMarkJobsReadyVisible(open)}
    />
    <ViewSaveDialog {...inputDialogState} />
    <MaterialReactTable table={table} />
  </>;
};

